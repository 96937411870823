<script setup lang="ts"></script>

<template>
  <div class="c-news-thumb-skeleton">
    <div class="c-news-thumb-skeleton__image sc-skeleton" />
    <div class="c-news-thumb-skeleton__hashtag sc-skeleton" />
    <div class="c-news-thumb-skeleton__title">
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
    </div>
    <div class="c-news-thumb-skeleton__info">
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
    </div>
    <div class="c-news-thumb-skeleton__description">
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
      <div class="sc-skeleton" />
    </div>
    <div class="c-news-thumb-skeleton__link sc-skeleton" />
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */

@use '@inkline/inkline/css/mixins' as *;
@use '@/assets/styles/mixins/skeleton' as skeleton;

.c-news-thumb-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow: hidden;

  .sc-skeleton {
    @include skeleton.skeleton;
  }

  &__image {
    width: 100%;
    aspect-ratio: 3 / 2;
    flex: 0 0 auto;
    min-height: 0;
    margin-bottom: 29px;

    @include breakpoint-down('sm') {
      margin-bottom: 20px;
    }
  }

  &__hashtag {
    width: 50%;
    height: 16px;
    margin-bottom: 13px;

    @include breakpoint-down('sm') {
      margin-bottom: 12px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 11px;

    @include breakpoint-down('sm') {
      margin-bottom: 10px;
    }

    .sc-skeleton {
      height: 24px;

      @include breakpoint-down('sm') {
        height: 22px;
      }

      &:nth-child(1) {
        width: 92%;
      }
      &:nth-child(2) {
        width: 82%;
        display: none;

        @include breakpoint-down('md') {
          display: block;
        }
      }
      &:nth-child(3) {
        width: 70%;
      }
    }
  }

  &__info {
    display: flex;
    gap: 10px;
    margin-bottom: 32px;

    @include breakpoint-down('sm') {
      margin-bottom: 24px;
    }

    .sc-skeleton {
      width: 20%;
      height: 14px;

      &:last-child {
        width: 36%;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    @include breakpoint-down('sm') {
      margin-bottom: 24px;
    }

    .sc-skeleton {
      height: 17px;

      @include breakpoint-down('sm') {
        height: 16px;
      }

      &:nth-child(1) {
        width: 76%;
      }
      &:nth-child(2) {
        width: 93%;
      }
      &:nth-child(3) {
        width: 83%;
      }
      &:nth-child(4) {
        width: 42%;
      }
    }
  }

  &__link {
    width: 46%;
    height: 18px;
  }
}
</style>
